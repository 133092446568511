import React, {createContext, useState, useEffect} from 'react';
import fetch from 'cross-fetch';
const API_URL = 'https://netilradio.airtime.pro/api/live-info';

const data = {
  schedule: {},
  lastUpdated: null,
};

const ScheduleContext = createContext({ store: data });

export const Provider = ({children}) => {
  const [store, setStore] = useState(data);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const updateData = async () => {
    const data = await fetch(API_URL).then(response => response.json());
    if (!isUnmounted) {
      setStore({
        schedule: data,
        lastUpdated: new Date(),
      });

      const now = new Date();
      const minutes = now.getMinutes();
      // Check at 00 and 30 past every hour
      let timeout = (30 - minutes + (Math.floor(minutes / 30) * 30)) * 1000 * 60;

      if (data.next) {
        const nextStartsAt = data.nextShow[0].starts;
        if (nextStartsAt && new Date(nextStartsAt) < new Date()) {
          timeout = 30000;
        }
      }

      setTimeout(() => updateData(), timeout);
    }
  };

  useEffect(() => {
    updateData();

    return () => {
      setIsUnmounted(true);
    }
  }, []);

  return (
    <ScheduleContext.Provider value={{store, updateData}}>
      {children}
    </ScheduleContext.Provider>
  );
};

export default ScheduleContext;
